import { useState, useEffect } from "react";

import './Details.css';

import Select from 'react-select';

import { config } from "dataFixed";
import JoditEditor from 'jodit-react';

import { SetModalData, SetModalState } from "interface/PopUp";
import { GetDataPage, RegisterDataPage } from "interface/Data";
import { GetUserData, RegisterUserData } from "interface/Users";
import { GetListPag, RegisterListPag, SetListPag } from "interface/Page";

import { Svg_Delete, Svg_Img, Svg_Return, Svg_Save } from "components/Svg";

import { cpfMask } from "services/Mask";

import { Reg_Owner } from "services/RegisterData";

export default function Page_LegalResponsible_Details(){

    const [ loading, setLoading ]   = useState(false);
    const [ userId, setUserId ]     = useState(GetUserData('id'));  
    const [ idPage, setIdPage ]     = useState(GetListPag('currentPageId'));
    const [ dataPage, setDataPage ] = useState(GetDataPage('owner'));

    const [ cpf, setCpf ]         = useState(InitialData('cpf'));
    const [ name, setName ]       = useState(InitialData('name'));
    const [ email, setEmail ]     = useState(InitialData('email'));
    const [ photo, setPhoto ]     = useState(InitialData('photo'));
    const [ status, setStatus ]   = useState(false);
    const [ contact, setContact ] = useState(InitialData('contact'));

    useEffect(()=>{
        RegisterUserData('id', setUserId);
        RegisterListPag('currentPageId', setIdPage);
        RegisterDataPage('owner', setDataPage);
    }, []);

    useEffect(()=>{
        setIdPage(GetListPag('currentPageId'));
        setDataPage(GetDataPage('owner')); 

        setCpf(InitialData('cpf'));
        setName(InitialData('name'));
        setEmail(InitialData('email'));
        setPhoto(InitialData('photo'));
        setContact(InitialData('contact'));
        setStatus(false);
    }, [dataPage, idPage]);

    function InitialData(type){
        if(idPage != 0){
            const newData = dataPage.find(item => item.id == idPage);
            return newData[type];
        }
        return '';
    }

    function AddFile(value){
        if(value){
            setStatus(true);
            setPhoto(value);
        }else {
            setStatus(false);
            setPhoto('');
        }
    }

    function OpenFile(type, value){
        SetModalData("ShowFile", { "type": type, "file": value });
        SetModalState('ShowFile', true);
    }

    function ReturnPage(){
        SetListPag('currentPageId', 'remuve');
        SetListPag('currentPage', 'legal_responsible');
    }

    function SaveData(event){
        setLoading(true);
        event.preventDefault();
        Reg_Owner(idPage, userId, cpf, name, email, photo, contact, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
    }

    function CallbackSuccess(){
        setLoading(false);
        SetModalData('ReturnResponse', { "page": "ok" });
        SetModalState('ReturnResponse', true);
    }

    function CallbackError(){
        setLoading(false);
        SetModalData('ReturnResponse', { "page": "erro" });
        SetModalState('ReturnResponse', true);
    }
    
    return(
        <div className="Page_LegalResponsible_Details">
            <div className={ loading == true ? "return_data_save div_loading" : "return_data_save" }>
                <div className="newtons-cradle">
                    <div className="newtons-cradle__dot" />
                    <div className="newtons-cradle__dot" />
                    <div className="newtons-cradle__dot" />
                    <div className="newtons-cradle__dot" />
                </div>
            </div>

            <form onSubmit={ SaveData }>
                <div className="new_block">
                    <div className="div_return" onClick={ ()=>{ ReturnPage() }}>
                        <Svg_Return className="icons" color="#061821" />
                        Voltar
                    </div>
                    <div className="title_page">Detalhes</div>
                    <button className="new_block_text">
                        <Svg_Save color="#ffffff" className="icons" />
                        Salvar
                    </button>
                </div>

                <div className="show_page_data">
                    <div className="list_type_data">
                        <div className="type_title">Dados do responsável</div>
                    </div>
                    <div className="list_input_data">
                        <div className="div_input">
                            <input type="text" className="cpf" onChange={ (e)=>{ setCpf(e.target.value); } } value={ cpfMask(cpf) } maxLength="14" required />
                            <span className="name_input">CPF*</span>
                        </div>
                        <div className="div_input space_div">
                            <input type="text" className="" onChange={ (e)=>{ setContact(e.target.value); } } value={ contact } maxLength="60" required />
                            <span className="name_input">Contato*</span>
                        </div>                        
                        <span className="div_input div_add_img">
                            <div className={ status == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                <label>
                                    { status == true ? "Foto adicionada" : "Adicionar foto" }
                                    <input type="file" onChange={ (e)=>{ AddFile(e.target.files[0]) } } accept="image/*" />
                                </label>
                            </div>
                            {
                                photo !='' ?
                                <>                                        
                                    <div className="delete_img_add" onClick={ ()=>{ AddFile('') } }>
                                        <Svg_Delete className="icons" color="#ffffff" />
                                    </div>
                                    {
                                        status == true ?
                                        <div className="show_img_" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(photo)) } }>
                                            <Svg_Img color="#324d6b" className="icons"/>
                                        </div> :
                                        <div className="show_img_" onClick={ ()=>{ OpenFile('showImg', "owner/" + photo) } }>
                                            <Svg_Img color="#324d6b" className="icons"/>
                                        </div>
                                    }
                                </>
                                : null
                            }
                        </span>
                    </div>
                    <div className="list_input_data">
                        <div className="div_input space_div">
                            <input type="text" className="" onChange={ (e)=>{ setName(e.target.value); } } value={ name } maxLength="140" required />
                            <span className="name_input">Nome*</span>
                        </div>
                        <div className="div_input space_div">
                            <input type="email" className="" onChange={ (e)=>{ setEmail(e.target.value); } } value={ email } maxLength="80" required />
                            <span className="name_input">Email*</span>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
}