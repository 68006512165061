
let DataPage = {
    "estados" : [],

    "access_dash" : [],
    
    "condominium"          : [],
    "condominium_fraction" : [],

    "unit": [],

    "owner"     : [],
    "list_city" : []
};

let NotifyDataPage = {
    "estados" : [],

    "access_dash" : [],

    "condominium"          : [],
    "condominium_fraction" : [],

    "unit": [],

    "owner"     : [],
    "list_city" : []
}

export function SetListData(value) {
    DataPage = value;
    NotifyDataPage["estados"].forEach(element => {
        console.log(element);
        element(value["estados"]); 
    });
    NotifyDataPage["access_dash"].forEach(element => {
        element(value["access_dash"]); 
    });
    NotifyDataPage["condominium"].forEach(element => {
        element(value["condominium"]); 
    });
    NotifyDataPage["condominium_fraction"].forEach(element => {
        element(value["condominium_fraction"]); 
    });
    NotifyDataPage["unit"].forEach(element => {
        element(value["unit"]); 
    });
    NotifyDataPage["owner"].forEach(element => {
        element(value["owner"]); 
    });
    NotifyDataPage["list_city"].forEach(element => {
        element(value["list_city"]); 
    });
}

export function SetListDataSingle(key, value) {
    DataPage[key] = value;
    NotifyDataPage[key].forEach(element => {
        element(value);
    });
}

export function GetDataPage(key){
    return DataPage[key];
}

export function RegisterDataPage(key, value){
    if(!NotifyDataPage[key]){
        NotifyDataPage[key] = [];
    }
    NotifyDataPage[key].push(value);
}
