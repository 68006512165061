import Axios from 'axios';

import Cookies from 'universal-cookie';

import { SetListPag } from 'interface/Page';
import { SetListDataSingle } from 'interface/Data';

import { cookiesRegister, typeCookiePageId } from 'dataFixed';

// Delete
export function Reg_Delete(userId, id, origin, type, CallbackSuccess, CallbackError){
    const data    = new FormData();

    data.append("id", id);
    data.append('idUser', userId);
    data.append('type_page', origin);
    data.append('edit_data', type);
    console.log(type);

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        SetListDataSingle(origin, response.data[origin]);
        CallbackSuccess();

    }).catch((error)=>{

        CallbackError();
    })
}

// Condominium
export function Reg_Condominium(idPage, idUser, cnpj, logotipo, fantasyName, listDetails, typeEdification, corporateReason, blockEdification, listFractionIndex, city, newCity, burgh, newBurgh, uf, cep, number, reference, complement, publicPlace, listContact, CallbackSuccess, CallbackError){
    const cookies = new Cookies();
    const data    = new FormData();
    data.append('type_page', 'condominium');
    data.append('edit_data', 'edit_or_register');

    data.append("idPage", idPage);
    data.append("idUser", idUser);
    data.append("cnpj", cnpj);
    data.append("logotipo", logotipo);
    data.append("fantasy_name", fantasyName);
    data.append("type_edification", typeEdification);
    data.append("corporate_reason", corporateReason);
    data.append("block_edification", blockEdification);
    data.append("city", city);
    data.append("new_city", newCity);
    data.append("burgh", burgh);
    data.append("new_burgh", newBurgh);
    data.append("uf", uf);
    data.append("cep", cep);
    data.append("number", number);
    data.append("reference", reference);
    data.append("complement", complement);
    data.append("public_place", publicPlace);

    // list details
    listDetails.forEach((element, index) => {
        data.append("list_details_id[]", element.id);
        data.append("list_details_text[]", element.text);
    });
    // list fraction index
    listFractionIndex.forEach((element, index) => {
        data.append("list_fraction_index_id[]", element.id);
        data.append("list_fraction_index_id_fraction[]", element.id_fraction);
        data.append("list_fraction_index_newFraction[]", element.newFraction);
    });
    // list contact
    listContact.forEach((element, index) => {
        data.append("list_contact_id[]", element.id);
        data.append("list_details_name[]", element.name);
        data.append("list_details_email[]", element.email);
        data.append("list_details_phone[]", element.phone);
    });

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        cookies.set(typeCookiePageId, response.data.idPage, '/', cookiesRegister);
        SetListPag('currentPageId', response.data.idPage);
        
        SetListDataSingle('condominium', response.data.condominium);
        SetListDataSingle('condominium_fraction', response.data.condominium_fraction);
        SetListDataSingle('list_city', response.data.list_city);
        CallbackSuccess();
    }).catch((error)=>{
        CallbackError();
    })
}

// Unit
export function Reg_Unit(idPage, userId, id_condominium, unit, type, area, owner, ownerName, email, publicPlace, idealFraction, cep, number, address, reference, complement, listTenants, listVehicles, listRealEstate, listRelatedPeople, CallbackSuccess, CallbackError){
    const cookies = new Cookies();
    const data    = new FormData();
    data.append('type_page', 'unit');
    data.append('edit_data', 'edit_or_register');

    data.append("idPage", idPage);
    data.append("idUser", userId);
    data.append("id_condominium", id_condominium);
    data.append("unit", unit);
    data.append("type", type);
    data.append("area", area);
    data.append("owner", owner);
    data.append("ownerName", ownerName);
    data.append("email", email);
    data.append("public_place", publicPlace);
    data.append("ideal_fraction", idealFraction);
    data.append("cep", cep);
    data.append("number", number);
    data.append("address", address);
    data.append("reference", reference);
    data.append("complement", complement);

    // list tenants
    listTenants.forEach((element, index) => {
        data.append("list_tenants_id[]", element.id);
        data.append("list_tenants_name[]", element.name);
        data.append("list_tenants_email[]", element.email);
        data.append("list_tenants_phone[]", element.phone);
    });

    // list realEstate
    listRealEstate.forEach((element, index) => {
        data.append("list_real_estate_id[]", element.id);
        data.append("list_real_estate_cep[]", element.cep);
        data.append("list_real_estate_brand[]", element.brand);
        data.append("list_real_estate_number[]", element.number);
        data.append("list_real_estate_complement[]", element.complement);
        data.append("list_real_estate_reference[]", element.reference);
    });

    // list related people
    listRelatedPeople.forEach((element, index) => {
        data.append("list_related_people_id[]", element.id);
        data.append("list_related_people_name[]", element.name);
        data.append("list_related_people_email[]", element.email);
        data.append("list_related_people_phone[]", element.phone);
    });
    
    // list vehicles
    listVehicles.forEach((element, index) => {
        data.append("list_vehicles_id[]", element.id);
        data.append("list_vehicles_brand[]", element.brand);
        data.append("list_vehicles_plate[]", element.plate);
        data.append("list_vehicles_color[]", element.color);
    });

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        console.log(response.data);
        cookies.set(typeCookiePageId, response.data.idPage, '/', cookiesRegister);
        SetListPag('currentPageId', response.data.idPage);
        
        SetListDataSingle('unit', response.data.unit);
        CallbackSuccess();
    }).catch((error)=>{
        CallbackError();
    })
}

// owner
export function Reg_Owner(idPage, userId, cpf, name, email, photo, contact, CallbackSuccess, CallbackError){
    const cookies = new Cookies();
    const data    = new FormData();
    data.append('type_page', 'owner');
    data.append('edit_data', 'edit_or_register');

    data.append("idPage", idPage);
    data.append("idUser", userId);
    data.append("cpf", cpf);
    data.append("name", name);
    data.append("email", email);
    data.append("photo", photo);
    data.append("contact", contact);

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        console.log(response.data);
        cookies.set(typeCookiePageId, response.data.idPage, '/', cookiesRegister);
        SetListPag('currentPageId', response.data.idPage);
        
        SetListDataSingle('owner', response.data.owner);
        CallbackSuccess();
    }).catch((error)=>{
        CallbackError();
    })
}