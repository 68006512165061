import { useState, useEffect } from "react";

import './LegalResponsible.css';

import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

import { SetListPag } from "interface/Page";
import { SetModalData, SetModalState } from "interface/PopUp";
import { GetDataPage, RegisterDataPage } from "interface/Data";
import { GetUserData, RegisterUserData } from "interface/Users";

import { Svg_Delete, Svg_Edit, Svg_New_Data, Svg_Search } from "components/Svg";

export default function Page_LegalResponsible(props){

    const [ search, setSearch ]     = useState('');
    const [ userId, setUserId ]     = useState(GetUserData('id'));
    const [ dataPage, setDataPage ] = useState(GetDataPage('owner'));

    const [ itensPage, setItensPage ] = useState(50);
    const [ showPage, setShowPage ]   = useState(0);

    const page         = Math.ceil(dataPage.length / itensPage);
    const startItens   = showPage * itensPage;
    const endItens     = startItens + itensPage;
    const currentItens = dataPage.slice(startItens, endItens);

    useEffect(()=>{
        RegisterUserData('id', setUserId);
        RegisterDataPage('owner', setDataPage);
    }, []);

    function SearchInput(value){
        const newList = [];
        if(value){
            GetDataPage('owner').forEach(item =>{
                if(item.name.toLowerCase().indexOf(value.toLowerCase()) != -1){
                    newList.push(item);
                }
                if(item.email.toLowerCase().indexOf(value.toLowerCase()) != -1){
                    newList.push(item);
                }
                if(item.contact.toLowerCase().indexOf(value.toLowerCase()) != -1){
                    newList.push(item);
                }
            });
            const duplicate = newList.filter((item, index) => newList.indexOf(item) === index);
            setDataPage(duplicate);
        }else {
            setDataPage(GetDataPage('owner'));
        }
        setSearch(value);
    }

    function PageClick(idPage){
        SetListPag('currentPage', 'legal_responsible_details')
        SetListPag('currentPageId', idPage);
    }

    function DeleteData(id, value){
        SetModalData('Confirmation', { "origin": "owner", "type" : "delete", "id" : id, "name": value });
        SetModalState('Confirmation', true);
    }

    return(
        <div className="Page_Unit">
            <div className="new_block">
                <div className="input_search">
                    <Svg_Search color="#111827" className="icons_search" />
                    <input type="text" className="search" onChange={ (e)=>{ SearchInput(e.target.value) } } value={ search } required placeholder="pesquisar..." />
                </div>
                <div className="title_page">Responsável legal</div>
                <div className="new_block_text" data-tooltip-id="new_data" data-tooltip-content="Cadastrar novo responsável legal" data-tooltip-place="top" onClick={ ()=>{ PageClick(0) } }>
                    <Svg_New_Data color="#ffffff" className="icons" />
                    responsável
                </div>
                <Tooltip id="new_data" />
            </div>
            <div className="list_data_page">
                <table width="100%" cellPadding="0" cellSpacing="0">
                    <thead>
                        <tr>
                            <th width="20" align="center">#</th>
                            <th>Nome</th>
                            <th width="120">E-mail</th>
                            <th width="120">Contato</th>
                            <th width="60" align="right">#</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            currentItens.map((key, index)=>{                                
                                return(
                                    <tr key={ index }>
                                        <td align="center">{ startItens + index + 1 }</td>
                                        <td>{ key.name }</td>
                                        <td>{ key.email }</td>
                                        <td>{ key.contact }</td>
                                        <td align="right">
                                            <div className="div_opt_alt">
                                                <div data-tooltip-id={ "remuve_" + key.id } data-tooltip-content="Apagar responsável" data-tooltip-place="top" onClick={ ()=>{ DeleteData(key.id, key.fantasy_name) } }>
                                                    <Svg_Delete color="#F00000" className="icons"/>
                                                </div>
                                                <Tooltip id={ "remuve_" + key.id } />
                                                <div data-tooltip-id={ "edit_" + key.id } data-tooltip-content="Editar responsável" data-tooltip-place="top" onClick={ ()=>{ PageClick(key.id) } }>
                                                    <Svg_Edit color={ key.status === 0 ? "#ffffff" : "#324d6b" } className="icons"/>
                                                </div>
                                                <Tooltip id={ "edit_" + key.id } />
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
                {
                    dataPage.length >= 50 ?
                    <div className="list_pages">
                        {
                            Array.from(Array(page), (item, index)=>{
                                return(
                                    <div className={ showPage == index ? "numb_page" : "numb_page page_active" } key={ index } onClick={ (e)=>{ setShowPage(index) } }>
                                        { index + 1 }
                                    </div>
                                )
                            })
                        }
                    </div> : null
                }
            </div>
        </div>
    )
}