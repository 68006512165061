import { useState, useEffect } from "react";

import './Login.css';

import { SetModalState } from "interface/PopUp";

import { Svg_Logotipo } from "components/Svg";

import PopUP_RecuverPass from "components/PopUp/RecuverPass";

import { Access } from "services/AccessDash";

export default function Login(){

    const [ email, setEmail ]       = useState('admin@teste.com');
    const [ password, setPassword ] = useState('teste');
    const [ msgError, setMsgError ] = useState('');

    function saveData(event){
        event.preventDefault();
        Access('logar', email, password, ()=>{ }, CallbackError);
    }

    function CallbackError(){
        setMsgError('E-mail ou senha incorreta!');
    }

    function OpenRecuver(){
        SetModalState('RecuverPass', true);
    }

    return(
        <>
            <div className="Login">
                <form onSubmit={ saveData }>
                    <div className="content">
                        <div className="div_logo">
                            <Svg_Logotipo className="logo" color="#05161A" />
                        </div>
                        <div className="div_input">
                            <input type="email" onChange={ (e)=>{ setEmail(e.target.value) } } value={ email } required placeholder="exemplo@site.com.br" />
                            <span className="name_input">E-mail*</span>
                        </div>
                        <div className="div_input div_input_login">
                            <input type="password" onChange={ (e)=>{ setPassword(e.target.value) } } value={ password } required placeholder="******" />
                            <span className="name_input">Senha*</span>
                        </div>
                        <div className={ msgError ? "msg_error" : "" }>
                            { msgError }
                        </div>
                        <div className="div_logar">
                            <button type="submit" className="button">
                                Entrar
                            </button>
                        </div>
                        <div className="recuver_pass">
                            <div className="new_pass" />
                            <div className="open_recuver" onClick={ ()=>{ OpenRecuver() } }>
                                Recuperar a senha
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <PopUP_RecuverPass />
        </>
    )
}
