import { useState, useEffect } from "react";

import './Details.css';

import Select from 'react-select';

import { config } from "dataFixed";
import JoditEditor from 'jodit-react';

import { SetModalData, SetModalState } from "interface/PopUp";
import { GetDataPage, RegisterDataPage } from "interface/Data";
import { GetUserData, RegisterUserData } from "interface/Users";
import { GetListPag, RegisterListPag, SetListPag } from "interface/Page";

import { Svg_Delete, Svg_Img, Svg_Return, Svg_Save } from "components/Svg";

import { cepMask } from "services/Mask";

import { Reg_Unit } from "services/RegisterData";

export default function Page_Unit_Details(){

    const [ loading, setLoading ]     = useState(false);
    const [ userId, setUserId ]       = useState(GetUserData('id'));  
    const [ dataPage, setDataPage ]   = useState(GetDataPage('unit'));
    const [ showOwner, setShowOwner ] = useState(GetDataPage('owner'));
    const [ idPage, setIdPage ]       = useState(GetListPag('currentPageId'));
    
    const [ showCondominium, setShowCondominium ] = useState(GetDataPage('condominium'));

    const [ unit, setUnit ]                   = useState(InitialData('unit'));
    const [ type, setType ]                   = useState(InitialData('type'));
    const [ area, setArea ]                   = useState(InitialData('area'));
    const [ owner, setOwner ]                 = useState(InitialData('owner'));
    const [ email, setEmail ]                 = useState(InitialData('email'));
    const [ ownerName, setOwnerName ]         = useState(InitialData('owner_name'));
    const [ publicPlace, setPublicPlace ]     = useState(InitialData('public_place'));
    const [ idealFraction, setIdealFraction ] = useState(InitialData('ideal_fraction'));

    const [ condominium, setCondominium ]         = useState(InitialData('condominium'));
    const [ condominiumName, setCondominiumName ] = useState(InitialData('condominium_name'));
    
    const [ cep, setCep ]               = useState(InitialData('cep'));
    const [ number, setNumber ]         = useState(InitialData('number'));
    const [ address, setAddress ]       = useState(InitialData('address'));
    const [ reference, setReference ]   = useState(InitialData('reference'));
    const [ complement, setComplement ] = useState(InitialData('complement'));
    
    const [ listTenants, setListTenants ]             = useState(InitialData('list_tenants'));
    const [ listVehicles, setListVehicles ]           = useState(InitialData('list_vehicles'));
    const [ listRealEstate, setListRealEstate ]       = useState(InitialData('list_real_estate'));
    const [ listRelatedPeople, setListRelatedPeople ] = useState(InitialData('list_related_people'));

    useEffect(()=>{
        RegisterUserData('id', setUserId);
        RegisterDataPage('unit', setDataPage);
        RegisterListPag('currentPageId', setIdPage);
        
        RegisterDataPage('owner', setShowOwner);
        RegisterDataPage('condominium', setShowCondominium);
    }, []);

    useEffect(()=>{
        setDataPage(GetDataPage('unit'));
        setIdPage(GetListPag('currentPageId'));
        
        setShowOwner(GetDataPage('owner'));
        setShowCondominium(GetDataPage('condominium'));

        setUnit(InitialData('unit'));
        setType(InitialData('type'));
        setArea(InitialData('area'));
        setOwner(InitialData('owner'));
        setEmail(InitialData('email'));
        setOwnerName(InitialData('owner_name'));
        setPublicPlace(InitialData('public_place'));
        setIdealFraction(InitialData('ideal_fraction'));

        setCondominium(InitialData('condominium'));
        setCondominiumName(InitialData('condominium_name'));
    
        setCep(InitialData('cep'));
        setNumber(InitialData('number'));
        setAddress(InitialData('address'));
        setReference(InitialData('reference'));
        setComplement(InitialData('complement'));
    
        setListTenants(InitialData('list_tenants'));
        setListVehicles(InitialData('list_vehicles'));
        setListRealEstate(InitialData('list_real_estate'));
        setListRelatedPeople(InitialData('list_related_people'));
    }, [dataPage, idPage]);

    function InitialData(type){
        if(idPage != 0){
            const newData = dataPage.find(item => item.id == idPage);
            return newData[type];
        }
        if(type == 'list_tenants' || type == 'list_real_estate' || type == 'list_related_people' || type == 'list_vehicles'){
            return [];
        }
        if(type == 'type'){
            return 'casa';
        }
        if(type == 'owner' || type == 'condominium'){
            return 0;
        }
        if(type == 'owner_name' || type == 'condominium_name'){
            return "Selecione uma opção";
        }
        return '';
    }

    function ListOwner(){
        const newData = [{ "value": 0, "label": "Selecione uma opção" }];
        showOwner.map((key, index)=>{
            newData.push({ "value": key.id, "label": key.name });
        })
        return newData;
    }

    function ListCondominium(){
        const newData = [{ "value": 0, "label": "Selecione uma opção" }];
        showCondominium.map((key, index)=>{
            newData.push({ "value": key.id, "label": key.fantasy_name });
        })
        return newData;
    }

    function HandleTenants(type, index, value){
        const newData        = [...listTenants];
        newData[index][type] = value;
        setListTenants(newData);
    }
    function DeleteTenants(index, id, value){
        if(id == 0){
            const newData = [...listTenants];
            newData.splice(index, 1);
            setListTenants(newData);
        }else {
            SetModalData("Confirmation", { "id": id, "origin": "unit", "type": "delete_tenants", "name": value });
            SetModalState('Confirmation', true);
        }
    }

    function HandleRealEstate(type, index, value){
        const newData        = [...listRealEstate];
        newData[index][type] = value;
        setListRealEstate(newData);
    }
    function DeleteRealEstate(index, id, value){
        if(id == 0){
            const newData = [...listRealEstate];
            newData.splice(index, 1);
            setListRealEstate(newData);
        }else {
            SetModalData("Confirmation", { "id": id, "origin": "unit", "type": "delete_real_estate", "name": value });
            SetModalState('Confirmation', true);
        }
    }

    function HandleRelatedPeople(type, index, value){
        const newData        = [...listRelatedPeople];
        newData[index][type] = value;
        setListRelatedPeople(newData);
    }
    function DeleteRelatedPeople(index, id, value){
        if(id == 0){
            const newData = [...listRelatedPeople];
            newData.splice(index, 1);
            setListRelatedPeople(newData);
        }else {
            SetModalData("Confirmation", { "id": id, "origin": "unit", "type": "delete_related_people", "name": value });
            SetModalState('Confirmation', true);
        }
    }

    function HandleVehicles(type, index, value){
        const newData        = [...listVehicles];
        newData[index][type] = value;
        setListVehicles(newData);
    }
    function DeleteVehicles(index, id, value){
        if(id == 0){
            const newData = [...listVehicles];
            newData.splice(index, 1);
            setListVehicles(newData);
        }else {
            SetModalData("Confirmation", { "id": id, "origin": "unit", "type": "delete_vehicles", "name": value });
            SetModalState('Confirmation', true);
        }
    }

    function HandleRealEstate(type, index, value){
        const newData        = [...listRealEstate];
        newData[index][type] = value;
        setListRealEstate(newData);
    }
    function DeleteRealEstate(index, id, value){
        if(id == 0){
            const newData = [...listRealEstate];
            newData.splice(index, 1);
            setListRealEstate(newData);
        }else {
            SetModalData("Confirmation", { "id": id, "origin": "unit", "type": "delete_real_estate", "name": value });
            SetModalState('Confirmation', true);
        }
    }

    function ReturnPage(){
        SetListPag('currentPageId', 'remuve');
        SetListPag('currentPage', 'unit');
    }

    function SaveData(event){
        setLoading(true);
        event.preventDefault();
        Reg_Unit(idPage, userId, condominium, unit, type, area, owner, ownerName, email, publicPlace, idealFraction, cep, number, address, reference, complement, listTenants, listVehicles, listRealEstate, listRelatedPeople, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
    }

    function CallbackSuccess(){
        setLoading(false);
        SetModalData('ReturnResponse', { "page": "ok" });
        SetModalState('ReturnResponse', true);
    }

    function CallbackError(){
        setLoading(false);
        SetModalData('ReturnResponse', { "page": "erro" });
        SetModalState('ReturnResponse', true);
    }
    
    return(
        <div className="Page_Unit_Details">
            <div className={ loading == true ? "return_data_save div_loading" : "return_data_save" }>
                <div className="newtons-cradle">
                    <div className="newtons-cradle__dot" />
                    <div className="newtons-cradle__dot" />
                    <div className="newtons-cradle__dot" />
                    <div className="newtons-cradle__dot" />
                </div>
            </div>

            <form onSubmit={ SaveData }>
                <div className="new_block">
                    <div className="div_return" onClick={ ()=>{ ReturnPage() }}>
                        <Svg_Return className="icons" color="#061821" />
                        Voltar
                    </div>
                    <div className="title_page">Detalhes</div>
                    <button className="new_block_text">
                        <Svg_Save color="#ffffff" className="icons" />
                        Salvar
                    </button>
                </div>

                <div className="show_page_data">
                    <div className="list_type_data">
                        <div className="type_title">Dados do unidade</div>
                    </div>
                    <div className="list_input_data">
                        <div className="div_input">
                            <Select className="type" defaultOptions options={[{ "value": "Casa", "label": "Casa" }, { "value": "Apartamento", "label": "Apartamento" }]} onChange={ (e)=> { setType(e.value) } } value={ { "value": type, "label": type } } placeholder="..." required />
                            <span className="name_input">Tipo*</span>
                        </div>
                        <div className="div_input">
                            <input type="text" className="area" onChange={ (e)=>{ setArea(e.target.value); } } value={ area } maxLength="14" required />
                            <span className="name_input">Area em m²*</span>
                        </div>
                        <div className="div_input">
                            <input type="text" className="area" onChange={ (e)=>{ setIdealFraction(e.target.value); } } value={ idealFraction } maxLength="80" />
                            <span className="name_input">Fração ideal</span>
                        </div>
                        <div className="div_input">
                            <input type="email" className="email" onChange={ (e)=>{ setEmail(e.target.value); } } value={ email } maxLength="120" />
                            <span className="name_input">E-mail</span>
                        </div>
                    </div>
                    <div className="list_input_data">
                        <div className="div_input space_div">
                            <input type="text" className="" onChange={ (e)=>{ setUnit(e.target.value); } } value={ unit } maxLength="140" required />
                            <span className="name_input">Unidade*</span>
                        </div>
                        <div className="div_input space_div">
                            <input type="text" className="" onChange={ (e)=>{ setPublicPlace(e.target.value); } } value={ publicPlace } maxLength="140" />
                            <span className="name_input">Logradouro</span>
                        </div>
                    </div>
                    <div className="list_input_data">
                        <div className="div_input space_div">
                            <Select className="" defaultOptions options={ ListOwner() } onChange={ (e)=> { setOwner(e.value); setOwnerName(e.label); } } value={ { "value": owner, "label": ownerName } } placeholder="..." required="required" />
                            <span className="name_input">Proprietário*</span>
                        </div>
                        <div className="div_input space_div">
                            <Select className="" defaultOptions options={ ListCondominium() } onChange={ (e)=> { setCondominium(e.value); setCondominiumName(e.label); } } value={ { "value": condominium, "label": condominiumName } } placeholder="..." required />
                            <span className="name_input">Condomínio*</span>
                        </div>
                    </div>
                </div>

                <div className="show_page_data">
                    <div className="list_type_data">
                        <div className="type_title">Inquilinos</div>
                        <div className="new_data" onClick={ ()=>{ setListTenants([...listTenants, { "id": 0, "name": "", "email": "", "phone": "" }]); } }>
                            <span className="icons_menu">+</span> inquilino
                        </div>
                    </div>
                    {
                        listTenants.map((key, index)=>{
                            return(
                                <div className="list_input_data" key={ index }>
                                    <div className="div_input space_div">
                                        <input type="text" className="" onChange={ (e)=>{ HandleTenants("name", index, e.target.value); } } value={ key.name } maxLength="140" />
                                        <span className="name_input">Nome</span>
                                    </div>
                                    <div className="div_input">
                                        <input type="email" className="" onChange={ (e)=>{ HandleTenants("email", index, e.target.value); } } value={ key.email } maxLength="140" />
                                        <span className="name_input">Email</span>
                                    </div>
                                    <div className="div_input">
                                        <input type="text" className="" onChange={ (e)=>{ HandleTenants("phone", index, e.target.value); } } value={ key.phone } maxLength="60" />
                                        <span className="name_input">Contato</span>
                                    </div>
                                    <div className="div_input" onClick={ ()=>{ DeleteTenants(index, key.id, key.name) } } style={ { display: 'flex' } }>
                                        <Svg_Delete color="#f00000" className="icons" />
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>

                <div className="show_page_data">
                    <div className="list_type_data">
                        <div className="type_title">Imobiliárias</div>
                        <div className="new_data" onClick={ ()=>{ setListRealEstate([...listRealEstate, { "id": 0, "cep": "", "brand": "", "number": "", "complement": "", "reference": "" }]); } }>
                            <span className="icons_menu">+</span> imobiliária
                        </div>
                    </div>
                    {
                        listRealEstate.map((key, index)=>{
                            return(
                                <div className="list_input_data show_data_text" key={ index }>
                                    <div className="list_input_data space_div" style={ { marginTop: 0 } }>
                                        <div className="div_input">
                                            <input type="text" className="cep" onChange={ (e)=>{ HandleRealEstate("cep", index, e.target.value); } } value={ cepMask(key.cep) } maxLength="18" required />
                                            <span className="name_input">CEP*</span>
                                        </div>
                                        <div className="div_input space_div">
                                            <input type="text" className="" onChange={ (e)=>{ HandleRealEstate("brand", index, e.target.value); } } value={ key.brand } maxLength="140" />
                                            <span className="name_input">Endereço</span>
                                        </div>
                                        <div className="div_input">
                                            <input type="text" className="number" onChange={ (e)=>{ HandleRealEstate("number", index, e.target.value); } } value={ key.number } maxLength="10" required />
                                            <span className="name_input">Número*</span>
                                        </div>
                                        <div className="div_input space_div">
                                            <input type="text" className="" onChange={ (e)=>{ HandleRealEstate("complement", index, e.target.value); } } value={ key.complement } maxLength="140" />
                                            <span className="name_input">Complemento</span>
                                        </div>
                                        <div className="div_input" onClick={ ()=>{ DeleteRealEstate(index, key.id, "Imobiliária") } } style={ { display: 'flex' } }>
                                            <Svg_Delete color="#f00000" className="icons" />
                                        </div>
                                    </div>
                                    <div className="list_input_data space_div" style={ { marginTop: 0 } }>   
                                        <div className="div_input space_div">
                                            <div className="show_title">Referência</div>
                                            <JoditEditor className="height_textarea" config={ config } value={ key.reference !='' ? key.reference.replaceAll('&#34;', '"') : "" } onBlur={ newContent => HandleRealEstate("reference", index, newContent) } />
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>

                <div className="show_page_data">
                    <div className="list_type_data">
                        <div className="type_title">Endereço de cobrança</div>
                    </div>
                    <div className="list_input_data">
                        <div className="div_input space_div">
                            <input type="text" className="" onChange={ (e)=>{ setAddress(e.target.value); } } value={ address } maxLength="140" required />
                            <span className="name_input">Endereço*</span>
                        </div>
                    </div>
                    <div className="list_input_data">
                        <div className="div_input">
                            <input type="text" className="number" onChange={ (e)=>{ setNumber(e.target.value); } } value={ number } maxLength="10" required />
                            <span className="name_input">Número*</span>
                        </div>
                        <div className="div_input space_div">
                            <input type="text" className="" onChange={ (e)=>{ setComplement(e.target.value); } } value={ complement } maxLength="140" />
                            <span className="name_input">Complemento</span>
                        </div>
                        <div className="div_input">
                            <input type="text" className="cep" onChange={ (e)=>{ setCep(e.target.value); } } value={ cepMask(cep) } maxLength="18" required />
                            <span className="name_input">CEP*</span>
                        </div>
                    </div>
                    <div className="list_input_data">   
                        <div className="div_input space_div show_data_text">
                            <div className="show_title">Referência</div>
                            <JoditEditor className="height_textarea" config={ config } value={ reference !='' ? reference.replaceAll('&#34;', '"') : "" } onBlur={ newContent => setReference(newContent) } />
                        </div>
                    </div>
                </div>

                <div className="show_page_data">
                    <div className="list_type_data">
                        <div className="type_title">Pessoas relacionadas à unidade (morador, colaborador...)</div>
                        <div className="new_data" onClick={ ()=>{ setListRelatedPeople([...listRelatedPeople, { "id": 0, "name": "", "email": "", "phone": "" }]); } }>
                            <span className="icons_menu">+</span> pessoa
                        </div>
                    </div>
                    {
                        listRelatedPeople.map((key, index)=>{
                            return(
                                <div className="list_input_data" key={ index }>
                                    <div className="div_input space_div">
                                        <input type="text" className="" onChange={ (e)=>{ HandleRelatedPeople("name", index, e.target.value); } } value={ key.name } maxLength="140" />
                                        <span className="name_input">Nome</span>
                                    </div>
                                    <div className="div_input">
                                        <input type="email" className="" onChange={ (e)=>{ HandleRelatedPeople("email", index, e.target.value); } } value={ key.email } maxLength="140" />
                                        <span className="name_input">Email</span>
                                    </div>
                                    <div className="div_input">
                                        <input type="text" className="" onChange={ (e)=>{ HandleRelatedPeople("phone", index, e.target.value); } } value={ key.phone } maxLength="60" />
                                        <span className="name_input">Contato</span>
                                    </div>
                                    <div className="div_input" onClick={ ()=>{ DeleteRelatedPeople(index, key.id, "Detalhes") } } style={ { display: 'flex' } }>
                                        <Svg_Delete color="#f00000" className="icons" />
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>

                <div className="show_page_data">
                    <div className="list_type_data">
                        <div className="type_title">Veículos</div>
                        <div className="new_data" onClick={ ()=>{ setListVehicles([...listVehicles, { "id": 0, "brand": "", "plate": "", "color": "" }]); } }>
                            <span className="icons_menu">+</span> veículo
                        </div>
                    </div>
                    {
                        listVehicles.map((key, index)=>{
                            return(
                                <div className="list_input_data" key={ index }>
                                    <div className="div_input">
                                        <input type="text" className="brand" onChange={ (e)=>{ HandleVehicles("brand", index, e.target.value); } } value={ key.brand } maxLength="140" />
                                        <span className="name_input">Marca</span>
                                    </div>
                                    <div className="div_input">
                                        <input type="text" className="plate" onChange={ (e)=>{ HandleVehicles("plate", index, e.target.value); } } value={ key.plate } maxLength="8" />
                                        <span className="name_input">Placa</span>
                                    </div>
                                    <div className="div_input space_div">
                                        <input type="text" className="" onChange={ (e)=>{ HandleVehicles("color", index, e.target.value); } } value={ key.color } maxLength="60" />
                                        <span className="name_input">Cor</span>
                                    </div>
                                    <div className="div_input" onClick={ ()=>{ DeleteVehicles(index, key.id, "Detalhes") } } style={ { display: 'flex' } }>
                                        <Svg_Delete color="#f00000" className="icons" />
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </form>
        </div>
    )
}