import { useState, useEffect } from "react";

import './Content.css';

import Cookies from "universal-cookie";

import { GetUserData, RegisterUserData } from "interface/Users";
import { GetListPag, RegisterListPag, SetListPag } from "interface/Page";

import { cookiesRegister, typeCookie, typeCookieEmail, typeCookiePage, typeCookiePassw } from "dataFixed";

import { Svg_Arrow_Right, Svg_Dashboard, Svg_Logotipo, Svg_Menu_Building, Svg_Menu_Legal_Responsible, Svg_Menu_Point, Svg_User } from "components/Svg";

import Page_Condominium from "components/Page/Condominium";
import Page_Condominium_Details from "components/Page/Condominium/Details";
import PopUP_ShowFile from "components/PopUp/ShowFile";
import PopUP_ReturnResponse from "components/PopUp/ReturnResponse";
import PopUP_Confirmation from "components/PopUp/Confirmation";
import Page_Unit from "components/Page/Unit";
import Page_Unit_Details from "components/Page/Unit/Details";
import Page_LegalResponsible from "components/Page/LegalResponsible";
import Page_LegalResponsible_Details from "components/Page/LegalResponsible/Details";

export default function Content(){
    const cookies = new Cookies();
    const [ status, setStatus ] = useState(false);
    
    const [ currentPage, setCurrentPage ] = useState(GetListPag('currentPage'));
    
    const [ menuMobile, setMenuMobile ] = useState(false);
    
    const [ userFile, setUserFile ]     = useState(GetUserData('file'));
    const [ userName, setUserName ]     = useState(GetUserData('name'));
    const [ userAccess, setUserAccess ] = useState(GetUserData('access'));
    const [ userPage, setUserPage ]     = useState(GetUserData('page'));

    const typeUrl     = new URLSearchParams(window.location.search);
    const pageRecuver = typeUrl.get("recover");

    const [ menuCondominium, setMenuCondominium ] = useState(false);
    const [ listCondominium, setListCondominium ] = useState([
        "condominium", "condominium_details",,
        "unit", "unit_details",
        "legal_responsible", "legal_responsible_details"
    ]);

    useEffect(()=>{
        RegisterListPag('currentPage', setCurrentPage);

        RegisterUserData('file', setUserFile);
        RegisterUserData('name', setUserName);
        RegisterUserData('access', setUserAccess);
        RegisterUserData('page', setUserPage);

        window.onresize = function(e){
            if(e.target.innerWidth == 560){
                if(status == true){
                    setStatus(false);
                }
            }
        }
    }, []);

    function EditProfile(){

    }

    function CkickPage(value){
        SetListPag('currentPage', value);
        if(window.screen.width <= 560){
            setStatus(false);
        }
    }

    function CurrentPage(){
        switch(currentPage) {
            case "condominium":
                return <Page_Condominium CkickPage={ CkickPage } />;
            case "condominium_details":
                return <Page_Condominium_Details CkickPage={ CkickPage } />;

            case "unit":
                return <Page_Unit CkickPage={ CkickPage } />;
            case "unit_details":
                return <Page_Unit_Details CkickPage={ CkickPage } />;

            case "legal_responsible":
                return <Page_LegalResponsible CkickPage={ CkickPage } />;
            case "legal_responsible_details":
                return <Page_LegalResponsible_Details CkickPage={ CkickPage } />;
        }
    }

    function Logout(){
        SetListPag('page', 'index');

        window.history.pushState("", "", "/");
        cookies.set(typeCookie, 'index', '/', cookiesRegister);
        cookies.remove(typeCookieEmail, '', '/', cookiesRegister);
        cookies.remove(typeCookiePassw, '', '/', cookiesRegister);
        cookies.remove(typeCookiePage, '', '/', cookiesRegister);
    }
    
    function ClickOpenMenu(value){
        console.log('Teste 1');
        if(value == true){
            setStatus(false);
        }{
            setStatus(true);
        }
        document.querySelector('.div_click_menu_').addEventListener('click', function click(e) {
            document.querySelector('.div_click_menu_').removeEventListener('click', click);
        });
    }

    return(
        <div className="Content">
            <div className={ status == true ? "list_menu active_menu" : "list_menu" }>
                <div className="show_opt_menu">
                    <div className="show_data_top">
                        <div className="div_logo">
                            <div className={ status == true ? "div_logo_opt div_logo_opt_" : "div_logo_opt" }>
                                <div className="div_logotipo" onClick={ ()=>{ setStatus(!status) } }>
                                    {
                                        status == true ?
                                        <Svg_Logotipo color="#ffffff" className="logotipo logo_" /> :
                                        <Svg_Logotipo color="#ffffff" className="logotipo" />
                                    }
                                </div>
                                <div className={ status == true ? "div_click_menu_none" : "div_click_menu_block" }>
                                    <label className="burger" htmlFor="burger">
                                        <input type="checkbox" id="burger" value="true" onChange={ ()=>{ setStatus(!status) } } checked={ status } />
                                        <span />
                                        <span />
                                        <span />
                                    </label>
                                </div>
                            </div>
                        </div> 

                        <div className={ status == true ? "opt_menu_dash menu_open" : "opt_menu_dash menu_close" }>
                            {
                                userAccess == 1 ?
                                <>
                                    <div className={ currentPage == "index" ? "show_menu_dash active_dash" : status == true ? "show_menu_dash show_menu_dash_": "show_menu_dash" } onClick={ ()=>{ CkickPage("index"); setMenuCondominium(false); } }>
                                        <div className={ status == true ? "name_menu name_menu_" : "name_menu" }>
                                            <div className="div_icons">
                                                <Svg_Dashboard color="#FFC400" className="icons_dash" />
                                            </div>
                                            <div className={ status == true ? "menu_close_hide" : "" }>Dashboard</div>
                                        </div>
                                    </div>
                                    
                                    <div className={ status == true ? listCondominium.find(item => item == currentPage) ? "show_menu_dash active_dash" : "show_menu_dash show_menu_dash_" : "show_menu_dash" }>
                                        <div className={ status == true ? "name_menu name_menu_" : "name_menu" } onClick={ ()=>{ setMenuCondominium(!menuCondominium); } }>
                                            <div className="div_icons">
                                                <Svg_Menu_Building color="#FFC400" className="icons_dash" />
                                            </div>
                                            <div className={ status == true ? "menu_close_hide" : "" }>Condomínio</div>
                                            <div className={ status == true ? "menu_close_hide" : "icons_submenu" }>
                                                {
                                                    menuCondominium == true || listCondominium.find(item => item == currentPage) ?
                                                    <Svg_Arrow_Right color="#ffffff" className="icons icons_active" /> :
                                                    <Svg_Arrow_Right color="#ffffff" className="icons" />
                                                }
                                            </div>
                                        </div>
                                        {
                                            status == true ? null :                                        
                                            <div className={ menuCondominium == true || listCondominium.find(item => item == currentPage) ? "name_submenu submenu_active" : "name_submenu" }>
                                                <div className={ currentPage == "condominium" || currentPage == "condominium_details" ? "sub_menu sub_menu_show_dash show_menu_dash space_submenu active_dash" : "sub_menu sub_menu_show_dash show_menu_dash space_submenu" } onClick={ ()=>{ CkickPage("condominium") } }>
                                                    <div>Dados do condomínio</div>
                                                </div>
                                                <div className={ currentPage == "legal_responsible" || currentPage == "legal_responsible_details" ? "sub_menu sub_menu_show_dash show_menu_dash space_submenu active_dash" : "sub_menu sub_menu_show_dash show_menu_dash space_submenu" } onClick={ ()=>{ CkickPage("legal_responsible") } }>
                                                    <div>Responsável legal</div>
                                                </div>
                                                <div className={ currentPage == "unit" || currentPage == "unit_details" ? "sub_menu sub_menu_show_dash show_menu_dash space_submenu active_dash" : "sub_menu sub_menu_show_dash show_menu_dash space_submenu" } onClick={ ()=>{ CkickPage("unit") } }>
                                                    <div>Unidades</div>
                                                </div>
                                            </div>
                                        }
                                    
                                    </div>

                                    <div className={ currentPage == "access_dash" || currentPage == "access_dash_details" ? "show_menu_dash active_dash" : status == true ? "show_menu_dash show_menu_dash_": "show_menu_dash" } onClick={ ()=>{ CkickPage("access_dash"); setMenuCondominium(false); } }>
                                        <div className={ status == true ? "name_menu name_menu_" : "name_menu" }>
                                            <div className="div_icons">
                                                <Svg_User color="#FFC400" className="icons_dash" />
                                            </div>
                                            <div className={ status == true ? "menu_close_hide" : "" }>Usuários</div>
                                        </div>
                                    </div>
                                </>
                                :
                                <>
                                </>
                            }
                        </div>

                    </div>
                    
                    <div className={ status == true ? "div_profile profile_open" : "div_profile profile_close" }>
                        <div className={ status == true ? "div_profile_data div_profile_data_" : "div_profile_data" } onClick={ ()=>{ EditProfile() } }>
                            <div className="div_img">
                                {
                                    userFile !='' ?
                                    <img alt="profile user" src={ "./assets/login/" + userFile } className="show_img_profile" /> :
                                    <Svg_User color="#FFFFFF" className="show_img_profile" />
                                }
                            </div>
                            <div className={ status == true ? "name_user menu_close_hide" : "name_user" }>
                                { userName }
                            </div>
                            <div className={ status == true ? "open_profile menu_close_hide" : "open_profile" }>
                                <Svg_Menu_Point color="#ffffff" className="icons" />
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div className="list_data">
                <div className="show_data">
                    { CurrentPage() }
                </div>
            </div>
            <PopUP_Confirmation />
            <PopUP_ReturnResponse />
            <PopUP_ShowFile />
        </div>
    )
}