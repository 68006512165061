import { useState, useEffect } from "react";

import './Details.css';

import Select from 'react-select';

import { config } from "dataFixed";
import JoditEditor from 'jodit-react';

import { SetModalData, SetModalState } from "interface/PopUp";
import { GetDataPage, RegisterDataPage } from "interface/Data";
import { GetUserData, RegisterUserData } from "interface/Users";
import { GetListPag, RegisterListPag, SetListPag } from "interface/Page";

import { Svg_Delete, Svg_Img, Svg_Return, Svg_Save } from "components/Svg";

import { cepMask } from "services/Mask";

import { Reg_Condominium } from "services/RegisterData";

export default function Page_Condominium_Details(){

    const [ loading, setLoading ]           = useState(false);
    const [ userId, setUserId ]             = useState(GetUserData('id'));  
    const [ showCity, setShowCity ]         = useState(GetDataPage('list_city'));
    const [ idPage, setIdPage ]             = useState(GetListPag('currentPageId'));
    const [ dataPage, setDataPage ]         = useState(GetDataPage('condominium'));
    const [ showFraction, setShowFraction ] = useState(GetDataPage('condominium_fraction'));  

    const [ cnpj, setCnpj ]                           = useState(InitialData('cnpj'));
    const [ status, setStatus ]                       = useState(false);
    const [ logotipo, setLogotipo ]                   = useState(InitialData('logotipo'));
    const [ fantasyName, setFantasyName ]             = useState(InitialData('fantasy_name'));
    const [ listDetails, setListDetails ]             = useState(InitialData('list_details'));
    const [ typeEdification, setTypeEdification ]     = useState(InitialData('type_edification'));
    const [ corporateReason, setCorporateReason ]     = useState(InitialData('corporate_reason'));
    const [ blockEdification, setBlockEdification ]   = useState(InitialData('block_edification'));
    const [ listFractionIndex, setListFractionIndex ] = useState(InitialData('list_fraction_index'));

    const [ city, setCity ]         = useState(InitialData('city'));
    const [ newCity, setNewCity ]   = useState('');
    const [ cityName, setCityName ] = useState(InitialData('city_name'));

    const [ burgh, setBurgh ]         = useState(InitialData('burgh'));
    const [ newBurgh, setNewBurgh ]   = useState('');
    const [ burghName, setBurghName ] = useState(InitialData('burgh_name'));
    
    const [ uf, setUf ]                   = useState(InitialData('uf'));
    const [ cep, setCep ]                 = useState(InitialData('cep'));
    const [ number, setNumber ]           = useState(InitialData('number'));
    const [ reference, setReference ]     = useState(InitialData('reference'));
    const [ complement, setComplement ]   = useState(InitialData('complement'));
    const [ publicPlace, setPublicPlace ] = useState(InitialData('public_place'));

    const [ listContact, setListContact ] = useState(InitialData('list_contact'));

    useEffect(()=>{
        RegisterUserData('id', setUserId);
        RegisterListPag('currentPageId', setIdPage);
        RegisterDataPage('condominium', setDataPage);
        RegisterDataPage('condominium_fraction', setShowFraction);
        
        RegisterDataPage('list_city', setShowCity);
    }, []);

    useEffect(()=>{
        setShowCity(GetDataPage('list_city'));
        setIdPage(GetListPag('currentPageId'));
        setDataPage(GetDataPage('condominium'));
        setShowFraction(GetDataPage('condominium_fraction'));  

        setCnpj(InitialData('cnpj'));
        setStatus(false);
        setLogotipo(InitialData('logotipo'));
        setFantasyName(InitialData('fantasy_name'));
        setListDetails(InitialData('list_details'));
        setTypeEdification(InitialData('type_edification'));
        setCorporateReason(InitialData('corporate_reason'));
        setBlockEdification(InitialData('block_edification'));
        setListFractionIndex(InitialData('list_fraction_index'));

        setCity(InitialData('city'));
        setNewCity('');
        setCityName(InitialData('city_name'));

        setBurgh(InitialData('burgh'));
        setNewBurgh('');
        setBurghName(InitialData('burgh_name'));
    
        setUf(InitialData('uf'));
        setCep(InitialData('cep'));
        setNumber(InitialData('number'));
        setReference(InitialData('reference'));
        setComplement(InitialData('complement'));
        setPublicPlace(InitialData('public_place'));

        setListContact(InitialData('list_contact'));
    }, [dataPage, idPage]);

    function InitialData(type){
        if(idPage != 0){
            const newData = dataPage.find(item => item.id == idPage);
            return newData[type];
        }
        if(type == 'list_fraction_index' || type == 'list_details' || type == 'list_contact'){
            return [];
        }
        if(type == 'type_edification'){
            return "Vertical";
        }
        if(type == 'block_edification'){
            return "Não";
        }
        return '';
    }

    function HandleFraction(type, index, value){
        const newData        = [...listFractionIndex];
        newData[index][type] = value;
        setListFractionIndex(newData);
    }

    function ListFractionIndex(){
        const newData = [{ "value": 0, "label": "#" }, { "value": "newData", "label": "+ adicionar novo dado" }];
        showFraction.map((key, index)=>{
            newData.push({ "value": key.id, "label": key.name });
        })
        return newData;
    }

    function DeleteFraction(index, id, value){
        if(id == 0){
            const newData = [...listFractionIndex];
            newData.splice(index, 1);
            setListFractionIndex(newData);
        }else {
            SetModalData("Confirmation", { "id": id, "origin": "condominium", "type": "delete_fraction", "name": value });
            SetModalState('Confirmation', true);
        }
    }

    function HandleDetails(index, value){
        const newData          = [...listDetails];
        newData[index]['text'] = value;
        setListDetails(newData);
    }

    function DeleteDetails(index, id, value){
        if(id == 0){
            const newData = [...listDetails];
            newData.splice(index, 1);
            setListDetails(newData);
        }else {
            SetModalData("Confirmation", { "id": id, "origin": "condominium", "type": "delete_details", "name": value });
            SetModalState('Confirmation', true);
        }
    }

    function ListCity(){
        const newData = [{ "value": 0, "label": "#" }, { "value": "newData", "label": "+ adicionar nova cidade" }];
        showCity.map((key, index)=>{
            newData.push({ "value": key.id, "label": key.name });
        })
        return newData;
    }

    function ListBurgh(){
        const newData = [{ "value": 0, "label": "#" }, { "value": "newData", "label": "+ adicionar novo bairro" }];
        const idShowBurgh = showCity.find(item => item.id == city);
        if(idShowBurgh.list_burgh){
            idShowBurgh.list_burgh.map((key, index)=>{
                newData.push({ "value": key.id, "label": key.name });
            })
        }
        return newData;
    }

    function HandleContact(type, index, value){
        const newData        = [...listContact];
        newData[index][type] = value;
        setListContact(newData);
    }

    function DeleteContact(index, id, value){
        if(id == 0){
            const newData = [...listContact];
            newData.splice(index, 1);
            setListContact(newData);
        }else {
            SetModalData("Confirmation", { "id": id, "origin": "condominium", "type": "delete_contact", "name": value });
            SetModalState('Confirmation', true);
        }
    }

    function ReturnPage(){
        SetListPag('currentPageId', 'remuve');
        SetListPag('currentPage', 'condominium');
    }

    function AddFile(value){
        if(value){
            setStatus(true);
            setLogotipo(value);
        }else {
            setStatus(false);
            setLogotipo('');
        }
    }

    function OpenFile(type, value){
        SetModalData("ShowFile", { "type": type, "file": value });
        SetModalState('ShowFile', true);
    }

    function SaveData(event){
        setLoading(true);
        event.preventDefault();
        Reg_Condominium(idPage, userId, cnpj, logotipo, fantasyName, listDetails, typeEdification, corporateReason, blockEdification, listFractionIndex, city, newCity, burgh, newBurgh, uf, cep, number, reference, complement, publicPlace, listContact, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
    }

    function CallbackSuccess(){
        setLoading(false);
        SetModalData('ReturnResponse', { "page": "ok" });
        SetModalState('ReturnResponse', true);
    }

    function CallbackError(){
        setLoading(false);
        SetModalData('ReturnResponse', { "page": "erro" });
        SetModalState('ReturnResponse', true);
    }
    
    return(
        <div className="Page_Condominium_Details">
            <div className={ loading == true ? "return_data_save div_loading" : "return_data_save" }>
                <div className="newtons-cradle">
                    <div className="newtons-cradle__dot" />
                    <div className="newtons-cradle__dot" />
                    <div className="newtons-cradle__dot" />
                    <div className="newtons-cradle__dot" />
                </div>
            </div>

            <form onSubmit={ SaveData }>
                <div className="new_block">
                    <div className="div_return" onClick={ ()=>{ ReturnPage() }}>
                        <Svg_Return className="icons" color="#061821" />
                        Voltar
                    </div>
                    <div className="title_page">Detalhes</div>
                    <button className="new_block_text">
                        <Svg_Save color="#ffffff" className="icons" />
                        Salvar
                    </button>
                </div>

                <div className="show_page_data">
                    <div className="list_type_data">
                        <div className="type_title">Dados do gerais</div>
                    </div>
                    <div className="list_input_data">
                        <div className="div_input">
                            <input type="text" className="" onChange={ (e)=>{ setCnpj(e.target.value); } } value={ cnpj } maxLength="14" required />
                            <span className="name_input">CNPJ*</span>
                        </div>
                        
                        <span className="div_input div_add_img">
                            <div className={ status == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                <label>
                                    { status == true ? "Foto do condomínio adicionada" : "Adicionar foto do condomínio" }
                                    <input type="file" onChange={ (e)=>{ AddFile(e.target.files[0]) } } accept="image/*" />
                                </label>
                            </div>
                            {
                                logotipo !='' ?
                                <>                                        
                                    <div className="delete_img_add" onClick={ ()=>{ AddFile('') } }>
                                        <Svg_Delete className="icons" color="#ffffff" />
                                    </div>
                                    {
                                        status == true ?
                                        <div className="show_img_" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(logotipo)) } }>
                                            <Svg_Img color="#324d6b" className="icons"/>
                                        </div> :
                                        <div className="show_img_" onClick={ ()=>{ OpenFile('showImg', "client/logo/" + logotipo) } }>
                                            <Svg_Img color="#324d6b" className="icons"/>
                                        </div>
                                    }
                                </>
                                : null
                            }
                        </span>
                    </div>
                    <div className="list_input_data">
                        <div className="div_input space_div">
                            <input type="text" className="" onChange={ (e)=>{ setCorporateReason(e.target.value); } } value={ corporateReason } maxLength="140" required />
                            <span className="name_input">Razão social*</span>
                        </div>
                        <div className="div_input space_div">
                            <input type="text" className="" onChange={ (e)=>{ setFantasyName(e.target.value); } } value={ fantasyName } maxLength="140" required />
                            <span className="name_input">Nome fantasia / Apelido*</span>
                        </div>
                    </div>

                    <div className="list_input_data show_data_text space_div">   
                        <div className="div_add_new_data">
                            <div className="show_title">Edificação</div>
                        </div>
                        <div className="div_show_list">
                            <div className="div_list_data">
                                <div className="div_input">
                                    <Select className="type_edification" defaultOptions options={[{ "value": "Vertical", "label": "Vertical" }, { "value": "Horizontal", "label": "Horizontal" }]} onChange={ (e)=> { setTypeEdification(e.value) } } value={ { "value": typeEdification, "label": typeEdification } } placeholder="..." />
                                    <span className="name_input">Qual o tipo de edificação*</span>
                                </div>
                                <div className="div_input">
                                    <Select className="block_edification" defaultOptions options={[{ "value": "Sim", "label": "Sim" }, { "value": "Não", "label": "Não" }]} onChange={ (e)=> { setBlockEdification(e.value) } } value={ { "value": blockEdification, "label": blockEdification } } placeholder="..." />
                                    <span className="name_input">A edificação é dividida em blocos/quadras?</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="list_input_data show_data_text space_div">   
                        <div className="div_add_new_data">
                            <div className="show_title">Fração e indexadores de rateio</div>
                            <div className="new_data" onClick={ ()=>{ setListFractionIndex([...listFractionIndex, { "id": 0, "id_fraction": 0, "name_fraction": "...", "newFraction": "" }]) } }>
                                <span className="icons_menu">+</span> fração
                            </div>
                        </div>
                        <div className="div_show_list">
                            {
                                listFractionIndex.map((key, index)=>{
                                    return(
                                        <div className="div_list_data" key={ index }>
                                            <div className="div_input space_div">
                                                <Select className="" defaultOptions options={ ListFractionIndex() } onChange={ (e)=> { HandleFraction('id_fraction', index, e.value); HandleFraction('name_fraction', index, e.label) } } value={ { "value": key.id, "label": key.name_fraction } } placeholder="..." />
                                                <span className="name_input">Escolha uma opção listada</span>
                                            </div>
                                            {
                                                key.id_fraction != "newData" ? null :
                                                <div className="div_input space_div">
                                                    <input type="text" className="" onChange={ (e)=>{ HandleFraction('newFraction', index, e.target.value); } } value={ key.newFraction } maxLength="140" required />
                                                    <span className="name_input">Novo nome</span>
                                                </div>
                                            }
                                            <div className="div_input" onClick={ ()=>{ DeleteFraction(index, key.id, key.name_fraction) } } style={ { display: 'flex', marginTop: '16px' } }>
                                                <Svg_Delete color="#f00000" className="icons" />
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>

                    <div className="list_input_data show_data_text space_div">   
                        <div className="div_add_new_data">
                            <div className="show_title">Detalhes</div>
                            <div className="new_data" onClick={ ()=>{ setListDetails([...listDetails, { "id": 0, "text": "" }]) } }>
                                <span className="icons_menu">+</span> detalhe
                            </div>
                        </div>
                        <div className="div_show_list">
                            {
                                listDetails.map((key, index)=>{
                                    return(
                                        <div className="div_list_data show_data_text" key={ index }>
                                            <div className="div_input space_div">
                                                <JoditEditor className="height_textarea" config={ config } value={ key.text !='' ? key.text.replaceAll('&#34;', '"') : "" } onBlur={ newContent => HandleDetails(index, newContent) } />
                                            </div>
                                            <div className="div_input" onClick={ ()=>{ DeleteDetails(index, key.id, "Detalhes") } } style={ { display: 'flex', marginTop: '16px' } }>
                                                <Svg_Delete color="#f00000" className="icons" />
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>

                <div className="show_page_data">
                    <div className="list_type_data">
                        <div className="type_title">Endereço de cobrança</div>
                    </div>
                    <div className="list_input_data">
                        <div className="div_input space_div">
                            <input type="text" className="" onChange={ (e)=>{ setPublicPlace(e.target.value); } } value={ publicPlace } maxLength="140" required />
                            <span className="name_input">Logradouro*</span>
                        </div>
                    </div>
                    <div className="list_input_data">
                        <div className="div_input">
                            <input type="text" className="number" onChange={ (e)=>{ setNumber(e.target.value); } } value={ number } maxLength="10" required />
                            <span className="name_input">Número*</span>
                        </div>
                        <div className="div_input space_div">
                            <input type="text" className="" onChange={ (e)=>{ setComplement(e.target.value); } } value={ complement } maxLength="140" />
                            <span className="name_input">Complemento</span>
                        </div>
                        <div className="div_input">
                            <input type="text" className="cep" onChange={ (e)=>{ setCep(e.target.value); } } value={ cepMask(cep) } maxLength="18" required />
                            <span className="name_input">CEP*</span>
                        </div>
                        <div className="div_input">
                            <input type="text" className="uf" onChange={ (e)=>{ setUf(e.target.value); } } value={ uf } maxLength="2" required />
                            <span className="name_input">UF*</span>
                        </div>
                    </div>
                    <div className="list_input_data">
                        <div className="div_input space_div">
                            <Select className="" defaultOptions options={ ListCity() } onChange={ (e)=> { setCity(e.value); setCityName(e.label); } } value={ { "value": city, "label": cityName } } placeholder="..." />
                            <span className="name_input">Cidade*</span>
                        </div>
                        {
                            city == "newData" ? 
                            <>
                                <div className="div_input space_div">
                                    <input type="text" className="" onChange={ (e)=>{ setNewCity(e.target.value); } } value={ newCity } maxLength="140" />
                                    <span className="name_input">Nome da nova cidade</span>
                                </div>
                                <div className="div_input space_div">
                                    <input type="text" className="" onChange={ (e)=>{ setNewBurgh(e.target.value); } } value={ newBurgh } maxLength="140" />
                                    <span className="name_input">Nome do novo bairro</span>
                                </div>
                            </> : 
                                city == "" ? null :
                                <div className="div_input">
                                    <Select className="burgh" defaultOptions options={ ListBurgh() } onChange={ (e)=> { setBurgh(e.value); setBurghName(e.label); } } value={ { "value": burgh, "label": burghName } } placeholder="..." />
                                    <span className="name_input">Bairro*</span>
                                </div>                            
                        }
                        {
                            burgh == "newData" ? 
                            <div className="div_input space_div">
                                <input type="text" className="" onChange={ (e)=>{ setNewBurgh(e.target.value); } } value={ newBurgh } maxLength="140" />
                                <span className="name_input">Nome do novo bairro</span>
                            </div> : null
                        }
                    </div>
                    <div className="list_input_data">   
                        <div className="div_input space_div show_data_text">
                            <div className="show_title">Referência</div>
                            <JoditEditor className="height_textarea" config={ config } value={ reference !='' ? reference.replaceAll('&#34;', '"') : "" } onBlur={ newContent => setReference(newContent) } />
                        </div>
                    </div>
                </div>

                <div className="show_page_data">
                    <div className="list_type_data">
                        <div className="type_title">Contatos</div>
                        <div className="new_data" onClick={ ()=>{ setListContact([...listContact, { "id": 0, "name": "", "email": "", "phone": "" }]); } }>
                            <span className="icons_menu">+</span> contato
                        </div>
                    </div>
                    {
                        listContact.map((key, index)=>{
                            return(
                                <div className="list_input_data" key={ index }>
                                    <div className="div_input space_div">
                                        <input type="text" className="" onChange={ (e)=>{ HandleContact("name", index, e.target.value); } } value={ key.name } maxLength="140" />
                                        <span className="name_input">Nome</span>
                                    </div>
                                    <div className="div_input">
                                        <input type="email" className="" onChange={ (e)=>{ HandleContact("email", index, e.target.value); } } value={ key.email } maxLength="140" />
                                        <span className="name_input">Email</span>
                                    </div>
                                    <div className="div_input">
                                        <input type="text" className="" onChange={ (e)=>{ HandleContact("phone", index, e.target.value); } } value={ key.phone } maxLength="60" />
                                        <span className="name_input">Contato</span>
                                    </div>
                                    <div className="div_input" onClick={ ()=>{ DeleteContact(index, key.id, "Detalhes") } } style={ { display: 'flex' } }>
                                        <Svg_Delete color="#f00000" className="icons" />
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </form>
        </div>
    )
}