import React, { useState, useEffect } from "react";

import './RecuverPass.css';

import { SetModalState, RegisterModalData, RegisterModalObserver } from "interface/PopUp";

import { Svg_Close } from "components/Svg";

import { CheckedEmail } from "services/AccessDash"

export default function PopUP_RecuverPass(){

    const [ loading, setLoading ]     = useState(false);
    const [ modalData, setModaldata ] = useState({});
    const [ showPopUp, setShowPopUp ] = useState(false);

    const [ email, setEmail ]   = useState('');
    const [ pass, setPass ]     = useState('');

    const [ msgReturn, setMsgReturn ] = useState(0);

    useEffect(()=>{
        RegisterModalData('RecuverPass', setModaldata);
        RegisterModalObserver('RecuverPass', setShowPopUp);
    }, []);

    function SaveData(event){
        event.preventDefault();
        setLoading(true);
        CheckedEmail(email, ()=>{ ValidEmail() }, ()=>{ InvalidEmail() });        
    }

    function ValidEmail(){
        setTimeout(() => {
            setLoading(false);
            setMsgReturn(1);
        }, 1000);
    }

    function InvalidEmail(){
        setTimeout(() => {
            setLoading(false);
            setMsgReturn(2);
        }, 1000);
    }

    function ClosePopUp(){
        SetModalState('RecuverPass', false);
    }

    return (
        (showPopUp ?
            <>
                <div className={ loading == true ? "return_data_save div_loading" : "return_data_save" }>
                    <div className="newtons-cradle">
                        <div className="newtons-cradle__dot" />
                        <div className="newtons-cradle__dot" />
                        <div className="newtons-cradle__dot" />
                        <div className="newtons-cradle__dot" />
                    </div>
                </div>
                <form onSubmit={ SaveData }>
                    <div className="PopUp recuver_pass_center">
                        <div className="all recuver_pass">
                            <div className="div_data type_div">
                                <div className="title">
                                    Recuperar senha
                                </div>
                                <div className="close" onClick={ ()=>{ ClosePopUp(); } }>
                                    <Svg_Close color="#F00000" className="icons" />
                                </div>
                            </div>
                            <div className="div_data" style={ { paddingTop: 0 } }>
                                <div className="content">
                                    {
                                        msgReturn == 0 ?
                                        <>
                                            <div className="title_stage">
                                                Digite seu e-mail abaixo
                                            </div>                                
                                            <div className="list_input_data">
                                                <div className="div_input space_div">
                                                    <input type="email" className="space_width" onChange={ (e)=>{ setEmail(e.target.value) } } value={ email } maxLength="140" />
                                                    <span className="name_input">E-mail</span>
                                                </div>
                                            </div>
                                            <div className="register_data">
                                                <button type="submit">
                                                    Salvar
                                                </button>
                                            </div>
                                        </> :
                                        msgReturn == 1 ?
                                        <>
                                            <div className="title_stage">
                                                Foi enviado um e-mail para alteração da senha, verifique o span!
                                            </div>
                                        </> :
                                        <>
                                            <div className="title_stage">
                                                Não existe nenhum cadastro com o e-mail digitado!
                                            </div>
                                            <div className="div_alt_status" onClick={ ()=>{ setMsgReturn(0) } }>
                                                Clique aqui para alterar o e-mail digitado
                                            </div>
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </>
        : <React.Fragment></React.Fragment>)
    );
}
