import Cookies from 'universal-cookie';
import { cookiesRegister, typeCookiePage, typeCookiePageId } from '../dataFixed';

const cookies = new Cookies();

let ListPag = {
    "page"          : 'login',
    "currentPage"   : 'index',
    "currentPageId" : 0
};

let NotifyListPag = {
    "page"          : [],
    "currentPage"   : [],
    "currentPageId" : []
}

export function SetListPag(key, value){
    ListPag[key] = value;
    NotifyListPag[key].forEach(element => {
        element(value);
    });

    if(key == 'currentPage'){
        cookies.set(typeCookiePage, value, '/', cookiesRegister);
    }

    if(key == 'currentPageId'){
        if(value == 'remuve'){
            cookies.remove(typeCookiePageId, '', '/', cookiesRegister);
        }else {
            cookies.set(typeCookiePageId, value, '/', cookiesRegister);
        }
    }
}

export function GetListPag(key){
    return ListPag[key];
}

export function RegisterListPag(key, value){
    if(!NotifyListPag[key]){
        NotifyListPag[key] = [];
    }
    NotifyListPag[key].push(value);
}
